let apiEndpoint = "https://devmercuryrisk.azurewebsites.net";
let FEClientId = "948ff2a6-6a2a-47cf-a4b2-f3899553d151";
let BEClientId = "1f301d77-6d29-4227-b844-8b1caa731273";
let tenantId = "412259ed-cfdd-49be-8186-814dfe58dc54";

switch (window.location.host) {
	case "tomtom.mercuryrisk.com": {
		apiEndpoint = "https://tomtom-mercuryrisk-api.azurewebsites.net";
		FEClientId = "8db9243f-93e7-4511-9f6e-d6ac009ff3b0";
		BEClientId = "c9ea591d-45a5-4dc9-95e5-9011e0833e0a";
		tenantId = "efdd9521-f5a8-44c8-af38-15c1c87f3c75";
		break;
	}
	case "demo.mercuryrisk.com": {
		apiEndpoint = "https://demo-mercuryrisk-api.azurewebsites.net";
		break;
	}
	case "pov.mercuryrisk.com": {
		apiEndpoint = "https://pov-mercuryrisk-api.azurewebsites.net";
		break;
	}
	// case "localhost:4200": {
	// 	//apiEndpoint = "https://tomtom-mercuryrisk-api.azurewebsites.net"
	// 	// FEClientId = "948ff2a6-6a2a-47cf-a4b2-f3899553d151"
	// 	// BEClientId = "1f301d77-6d29-4227-b844-8b1caa731273"
	// }
}

export const environment = {
	production: true,
	apiEndpoint: apiEndpoint,
	serviceProvider: "Azure",
	authType: "OAuth",
	msalConfig: {
		auth: {
			clientId: FEClientId,
			authority: `https://login.microsoftonline.com/${tenantId}/`,
		},
	},
	apiConfig: {
		uri: apiEndpoint,
		scopes: [`api://${BEClientId}/access_as_user`],
	},
	graphAPI: {
		uri: "https://graph.microsoft.com/",
		scopes: ["user.read", "profile"],
	},
};
