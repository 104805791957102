import { HttpClient, HttpHeaders, type HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { injectMutation, injectQuery, injectQueryClient } from "@ngneat/query";
import buildQuery from "odata-query";
import { environment } from "../../environments/environment";
import type { UserRole } from "./user.service";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	private http = inject(HttpClient);
	private query = injectQuery();
	private mutation = injectMutation();
	private queryClient = injectQueryClient();

	getUserDetails() {
		return this.http.post("https://graph.microsoft.com/v1.0/$batch", {
			requests: [
				{
					id: "1",
					method: "GET",
					url: "/me?$select=id,accountEnabled,givenName,surname,displayName,mail,onPremisesSamAccountName,department,employeeId,companyName,country,mobilePhone,officeLocation,createdDateTime,userPrincipalName,jobTitle",
				},
				{
					id: "2",
					method: "GET",
					url: "/me/photo/$value",
				},
			],
		});
	}

	getUserRoles() {
		return this.query({
			queryKey: ["user-roles"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<UserRole>(`${environment.apiEndpoint}/api/UserInfo/user-detail`);
			},
		});
	}

	getAllRoles() {
		return this.query({
			queryKey: ["all-roles"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<any>(`${environment.apiEndpoint}/api/Roles/AllRoles`);
			},
		});
	}

	getAllUsers() {
		return this.query({
			queryKey: ["all-users"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<any>(`${environment.apiEndpoint}/api/UserInfo/AllUsers`);
			},
		});
	}

	getAVRGs() {
		const query = buildQuery({
			select: "Id,Name",
			expand: {
				AVRElement: {
					select: "Id,Name,RiskType,RiskTableMasterId",
					expand: {
						RiskCoefficient: { select: "Id,Coefficient" },
					},
				},
			},
		});
		return this.query({
			queryKey: ["avrg"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<AVRGroupResponse[]>(`${environment.apiEndpoint}/api/AVRGroups${query}`);
			},
		});
	}

	getAVRGElements() {
		const query = buildQuery({
			select: "Id,Name,RiskType",
			expand: {
				RiskCoefficient: {
					select: "Id,Coefficient,InformationSource",
					// expand: {
					// 	RiskCoefficient: { select: "id,coefficient,multiplier,divider" },
					// 	RiskTableMaster: { select: "id,name" },
					// },
				},
			},
		});
		return this.query({
			queryKey: ["elements"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,

			queryFn: () => {
				return this.http.get<any[]>(`${environment.apiEndpoint}/api/AVRGroups/elements${query}`);
			},
		});
	}

	getSingleAsset(id: number) {
		const query = buildQuery({
			select: "*",
			filter: `Id eq ${id}`,
			expand: {
				riskScore: {
					// filter: "InputValue ne ''",
					select: "AVRElement,ElementId,IntValue,InputValue,DisplayValue,CoefficientValue,Score",
					expand: {
						AVRElement: {
							select: "Id,Name,RiskType",
							expand: {
								AVRGroup: {
									select: "*",
									// expand: {
									// 	UserInfo: {
									// 		select: "Firstame,Lastame,Email"
									// 	},
									// },
								},
							},
						},
					},
				},
				ElementTicketDetail: {
					select: "*",
				},
			},
		});
		return this.http.get<RiskScoreResponseType[]>(`${environment.apiEndpoint}/api/Asset${query}`);
	}

	getSingleAssetBasic(id: number) {
		const query = buildQuery({
			select: "*",
			filter: `Id eq ${id}`,
		});
		return this.query({
			queryKey: ["asset", "singleBasic", id],
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<RiskScoreResponseType[]>(`${environment.apiEndpoint}/api/Asset${query}`);
			},
		});
	}

	getWhatIfScore(whatIfData: any) {
		return this.http.post(`${environment.apiEndpoint}/api/WhatIf`, whatIfData);
	}

	getOwnerAsset(name: string) {
		const query = buildQuery({
			select: "*",
			filter: `owner eq '${name}'`,
			expand: {
				riskScore: {
					// filter: "InputValue ne ''",
					select: "AVRElement,ElementId,IntValue,InputValue,DisplayValue,CoefficientValue,Score",
					expand: {
						AVRElement: {
							select: "Id,Name,RiskType",
							expand: {
								AVRGroup: {
									select: "*",
									// expand: {
									// 	UserInfo: {
									// 		select: "Firstame,Lastame,Email"
									// 	},
									// },
								},
								// ElementTicketDetail: {
								// 	select: "*",
								// },
							},
						},
					},
				},
				ElementTicketDetail: {
					select: "*",
				},
			},
		});
		return this.query({
			queryKey: ["owner", name] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<RiskScoreResponseType[]>(`${environment.apiEndpoint}/api/Asset${query}`);
			},
		});
	}
	getRiskScores() {
		const query = buildQuery({
			select: "*",
			expand: {
				riskScore: {
					filter: "Score ne 0",
					select: "AVRElement,ElementId,IntValue,InputValue,DisplayValue,CoefficientValue,Score",
					expand: {
						AVRElement: {
							select: "Id,Name,RiskType",
						},
					},
				},
			},
		});
		return this.query({
			queryKey: ["riskScore"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<RiskScoreResponseType[]>(`${environment.apiEndpoint}/api/Asset${query}`);
			},
		});
	}

	getRiskTables() {
		const query = buildQuery({
			select: "*",
			filter: "Id ne 58 and Id ne 59 and riskTableDetail/any()",
			expand: {
				riskTableDetail: { select: "*" },
				AVRGroup: { select: "*" },
			},
		});
		return this.query({
			queryKey: ["riskTable"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<any>(`${environment.apiEndpoint}/api/RiskTable/riskTable${query}`);
			},
		});
	}

	DeleteRiskTable(id: any) {
		return this.http.delete(`${environment.apiEndpoint}/api/RiskScores/risk-detail?Id=${id}`);
	}

	AddRiskTable(data: any) {
		return this.http.post(`${environment.apiEndpoint}/api/RiskScores/risk-detail`, data);
	}

	UpdateRiskdetail(data: any) {
		return this.http.put(`${environment.apiEndpoint}/api/RiskScores/risk-detail`, data);
	}

	getRiskCoeffiecient() {
		const query = buildQuery({
			select: "Id,Name",
			expand: {
				AVRElement: {
					select: "Id,Name,RiskType,RiskTableMasterId",
					expand: {
						RiskCoefficient: { select: "Id,Coefficient,InformationSource,Type,Multiplier,Divider" },
					},
				},
			},
		});
		return this.query({
			queryKey: ["riskCoefficient"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<any[]>(`${environment.apiEndpoint}/api/AVRGroups${query}`);
			},
		});
	}

	UpdateRiskTable(data: any, value: any) {
		return this.http.put(`${environment.apiEndpoint}/api/RiskScores/risk-coefficient`, data, value);
	}

	importFile() {
		return this.mutation({
			mutationFn: ({ formData }: { formData: FormData }) => {
				return this.http.post<FileUpload>(`${environment.apiEndpoint}/api/ImportFiles/file`, formData);
				// return this.http.get<FileUpload>(`${environment.apiEndpoint}/api/ImportFiles`);
			},
			onSuccess: () => {
				// this.queryClient.invalidateQueries({ queryKey: ["files"] });
			},
		});
	}

	updateDefaultRiskCoefficient() {
		return this.mutation({
			mutationKey: ["updateDefaultRiskCoefficient"],
			mutationFn: ({ formDataSend }: { formDataSend: any }) => {
				return this.http.put<any>(
					`${environment.apiEndpoint}/api/RiskCoefficient/default-risk-coefficient`,
					formDataSend,
				);
			},
			onSuccess: (data, variables, context) => {
				this.queryClient.invalidateQueries({ queryKey: ["riskCoefficient"] });
			},
		});
	}

	updateProductDetail() {
		return this.mutation({
			mutationFn: ({ id }: { id: number }) => {
				return this.http.put(
					`${environment.apiEndpoint}/api/ProductDetails/product-details?level=${id}`,
					null,
				);
			},
			onSuccess: () => {
				this.queryClient.invalidateQueries({ queryKey: ["asset"] });
				this.queryClient.invalidateQueries({ queryKey: ["riskScore"] });
				this.queryClient.invalidateQueries({ queryKey: ["percentileTable"] });
			},
		});
	}

	importFileConfirm() {
		return this.mutation({
			mutationFn: ({ id }: { id: number }) => {
				return this.http.put<any>(
					`${environment.apiEndpoint}/api/ImportFiles/confirm-upload?FileId=${id}`,
					null,
				);
			},
			onSuccess: () => {
				this.queryClient.invalidateQueries({ queryKey: ["files"] });
			},
		});
	}

	getImportFile(id: number) {
		return this.query({
			queryKey: ["file", id] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<any>(`${environment.apiEndpoint}/api/ImportFiles/content?FileId=${id}`);
			},
		});
	}

	getImportTable() {
		const query = buildQuery({
			select: "Id,fileName,type,status,created_by,created_at",
			filter: "status ne 'Deleted'",
			orderBy: "created_at desc",
		});
		return this.query({
			queryKey: ["files"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<any[]>(`${environment.apiEndpoint}/api/ImportFiles/all-files${query}`);
			},
		});
	}

	deleteImportFile() {
		return this.mutation({
			mutationFn: ({ id }: { id: number }) => {
				return this.http.delete(`${environment.apiEndpoint}/api/ImportFiles/file?FileId=${id}`);
			},
			onSuccess: () => {
				this.queryClient.invalidateQueries({ queryKey: ["files"] });
			},
		});
	}

	downloadImportFile(id: number) {
		return this.http.get(`${environment.apiEndpoint}/api/ImportFiles/download?FileId=${id}`, {
			responseType: "blob",
		});
	}

	getProductDetailReport(assetId: any) {
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			}),
			observe: "response" as const,
			responseType: "blob" as "json",
		};
		return this.http.get<Blob>(
			`${environment.apiEndpoint}/api/Export/product-detail?assetId=${assetId}`,
			httpOptions,
		);
	}

	getElementDetailReport(assetId: any, elementId: any) {
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			}),
			observe: "response" as const,
			responseType: "blob" as "json",
		};
		return this.http.get<Blob>(
			`${environment.apiEndpoint}/api/Export/element-detail?assetId=${assetId}&elementId=${elementId}`,
			httpOptions,
		);
	}

	getReviewPlanReport(assetId: any, riskType: any) {
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			}),
			observe: "response" as const,
			responseType: "blob" as "json",
		};
		return this.http.get<Blob>(
			`${environment.apiEndpoint}/api/Export/review-plan?assetId=${assetId}&riskType=${riskType}`,
			httpOptions,
		);
	}

	getPercentileTable() {
		const query = buildQuery({
			select: "*",
			filter: "Id eq 59",
			expand: {
				riskTableDetail: { select: "*" },
			},
		});
		return this.query({
			queryKey: ["percentileTable"] as const,
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			queryFn: () => {
				return this.http.get<any[]>(`${environment.apiEndpoint}/api/RiskTable/riskTable${query}`);
			},
		});
	}
	//logs api
	postlogsdata(data: any) {
		return this.http.post(`${environment.apiEndpoint}/api/RiskScoreLogs`, data);
	}

	getlogs(data: any) {
		return this.http.get(`${environment.apiEndpoint}/api/RiskScoreLogs`, {
			params: {
				page: "1",
				pageSize: "100",
				sort: "date",
				order: "asc",
				// startDate: "07-01-2024",
				// endDate: "07-12-2024",
				userId: "-1",
				componentName: data,
			},
		});
	}

	createTicket(data: any) {
		if (environment.serviceProvider === "Azure") {
			return this.http.post(`${environment.apiEndpoint}/api/AzureDevOps/createTicket`, data);
		}
		return this.http.post(`${environment.apiEndpoint}/api/AzureDevOps/createTicket`, data);
	}

	createBulkTicket(data: any[]) {
		if (environment.serviceProvider === "Azure") {
			return this.http.post(`${environment.apiEndpoint}/api/AzureDevOps/createTickets`, data);
		}
		return this.http.post(`${environment.apiEndpoint}/api/AzureDevOps/createTickets`, data);
	}
	//*profile demo api
	getprofile() {
		return this.http.get(`${environment.apiEndpoint}/api/UserInfo/demousers`);
	}

	updateElementPriority(elementTicketDetail: any) {
		return this.http.post<any[]>(
			`${environment.apiEndpoint}/api/ElementTicket/element-ticket`,
			elementTicketDetail,
		);
	}

	updateDetailedData(elementDetail: any) {
		return this.http.put<any[]>(`${environment.apiEndpoint}/api/ProductDetails/detailed-data`, elementDetail);
	}

	getDetailsData(assetId: any, elementId: any) {
		return this.http.get<RiskScoreResponseType[]>(
			`${environment.apiEndpoint}/api/RiskScores/pivot-risk-score?assetId=${assetId}&elementId=${elementId}`,
		);
	}

	getDetailsPivot(assetId: any, elementId: any) {
		return this.http.get<RiskScoreResponseType[]>(
			`${environment.apiEndpoint}/api/ProductDetails/pivot-data?assetId=${assetId}&elementId=${elementId}`,
		);
	}

	getReviewPlanData(assetId: any, riskType: string) {
		return this.http.get<RiskScoreResponseType[]>(
			`${environment.apiEndpoint}/api/ProductDetails/review-plan?assetId=${encodeURIComponent(assetId)}&riskType=${riskType}`,
		);
	}

	getRawData(assetId: any, elementId: any, costCenter: string) {
		return this.http.get<RiskScoreResponseType[]>(
			`${environment.apiEndpoint}/api/ProductDetails/raw-data?assetId=${assetId}&elementId=${elementId}&costCenter=${encodeURIComponent(costCenter)}`,
		);
	}
}

export type FileUpload = {
	id: number;
	name: string;
	url: string;
	client_id: number;
	type: string;
	status: string;
	created_by: number;
	created_at: string;
	fileName: string;
	isUploaded: boolean;
	isProcessed: boolean;
};

export type AVRGroupResponse = {
	Id: number;
	Name: string;
	AVRElement: {
		RiskCoefficient: {
			Id: number;
			Coefficient: number;
		};
		Id: number;
		Name: string;
		RiskType: string;
		RiskTableMasterId: number | null;
	}[];
};

export type RiskScoreResponseType = {
	AVRElement: any;
	RiskScore: {
		AVRElement: {
			Id: number;
			Name: string;
			RiskType: string;
			ElementTicketDetail: any[];
		};
		ElementId: number;
		IntValue: number;
		InputValue: string;
		DisplayValue: string;
		CoefficientValue: number;
		Score: number;
	}[];
	ElementTicketDetail: any[];
	Id: number;
	Name: string;
	Type: string;
	Owner: string;
	AvrScore: number;
	AddressableScore: number;
	riskBudgetPerformance: string;
	addressableOverage: number;
	likelihoodRisk: string;
	addressableTarget: number;
	addressableAppetite: number;
	addressableTolerance: number;
	addressableThreshold: number;
};
